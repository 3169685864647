'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IncidentPrioritiesManager

 # @description

###
angular
.module 'mundoAdmin.incidents'
.factory 'IncidentPrioritiesManager', (
  $mdDialog
  RestUtils
  MundoDefaultManager
  DispatchUnitTypesManager
  IncidentPriorityCodesManager
  IncidentPriorityDispatchUnitTypesManager
  TenantManager)->
    IncidentPrioritiesManagerBase = new MundoDefaultManager()

    IncidentPrioritiesManagerBase.setUrl('lpa/incidents/priorities')
    IncidentPrioritiesManagerBase.setAutoTenant()
    IncidentPrioritiesManagerBase.setNewObject(['label', 'color', 'weight', 'interruptable'])
    IncidentPrioritiesManagerBase.setUpdateObject(['label', 'color', 'weight', 'interruptable'])
    IncidentPrioritiesManagerBase.setSearchFields(['label', 'tenant.label'])


    IncidentPrioritiesManagerBase.new = (data) ->
      if (data.color)
        data.color = data.color.replace '#', ''

      @transport.all(@getUrl()).post(_.pick(data, @getNewObject()),{}, autoTenant : @conf.autoTenant )

    IncidentPrioritiesManagerBase.update = (id, data) ->
      if (data.color)
        data.color = data.color.replace '#', ''

      @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))


    IncidentPrioritiesManagerBase.getNewForm = ->
      [
        key: 'label'
        name: 'label'
        type: 'input'
        templateOptions:
          label: 'Label'
          placeholder: 'label'
          required: true
      ,
        key: 'color'
        name: 'color'
        type: 'color'
        templateOptions:
          label: 'Kleur'
          required: true
      ,
        key: 'weight'
        name: 'weight'
        type: 'input'
        templateOptions:
          label: 'Gewicht'
          placeholder: ''
          required: false
      ,
        key: 'interruptable'
        name: 'interruptable'
        type: 'checkbox'
        templateOptions:
          label: 'Interruptable'
          required: false
      ]
    IncidentPrioritiesManagerBase.getEditForm = (data) ->
      [
        key: 'label'
        name: 'label'
        type: 'input'
        defaultValue: data.label
        templateOptions:
          label: 'Label'
          placeholder: 'label'
          required: true
      ,
        key: 'color'
        name: 'color'
        type: 'color'
        defaultValue: '#' + data.color
        templateOptions:
          label: 'Kleur'
          placeholder: 'Kleur'
          required: true
      ,
        key: 'weight'
        name: 'weight'
        type: 'input'
        defaultValue: data.weight
        templateOptions:
          label: 'Gewicht'
          placeholder: ''
          required: false
      ,
        key: 'interruptable'
        name: 'interruptable'
        type: 'checkbox'
        defaultValue: data.interruptable
        templateOptions:
          label: 'Interruptable'
          required: false
      ]

    IncidentPrioritiesManagerBase.getListSchema = ->
      [
        key: 'label'
        title: 'datatable.label.label'
        sort: 'label'
      ,
        key: 'color'
        title: 'datatable.label.color'
        sort: 'color'
        type: 'color'
      ,
        key: 'weight'
        title: 'datatable.label.weight'
        sort: 'weight'
      ,
        key: 'interruptable'
        title: 'datatable.label.interruptable'
        sort: 'interruptable'
        type: 'boolean'
#       ,
#         key: 'tenant.label'
#         title: 'datatable.label.tenant'
# #        sort: 'tenant.label'
      ]

    IncidentPrioritiesManagerBase.getDetailTemplateUrl = ->
      'mundo-admin/incidents/views/incident-priority-detail.tpl.html'

  #   codes
    IncidentPrioritiesManagerBase.setObject('incidentPriorityCode', ['code', 'tenant'])

    IncidentPrioritiesManagerBase.getCodes = (id) ->
      RestUtils.getFullList @transport.one(@conf.url, id).all('codes')

    IncidentPrioritiesManagerBase.addCode = (id, data) ->
      @transport.one(@conf.url, id).all('codes').post(_.pick(data, @getObject('incidentPriorityCode')))

    IncidentPrioritiesManagerBase.removeCode = (incidentPriorityId, codeId) ->
      @transport.one(@conf.url, incidentPriorityId).one('codes', codeId).remove()

    IncidentPrioritiesManagerBase.getCodeForm = ->
      [
        key: 'code'
        name: 'code'
        type: 'input'
        templateOptions:
          label: 'Code'
          placeholder: 'code'
          required: true
      ]

    assignCode =
      [
        icon: 'code'
        text: 'datatable.tooltips.addCode'
        action: (id) ->
          $mdDialog.show
            templateUrl: 'mundo-admin/incidents/views/incident-priority-codes.modal.tpl.html'
            controller: 'IncidentPriorityCodesCtrl'
            controllerAs: 'ctrl'
            locals:
              id: id
      ]

    IncidentPrioritiesManagerBase.addExtraAction(assignCode)

    #   dispatchUnitTypes
    IncidentPrioritiesManagerBase.setObject('incidentPriorityDispatchUnitType', ['dispatchUnitType', 'minimalAmount'])

    IncidentPrioritiesManagerBase.getDispatchUnitTypes = (id) ->
      RestUtils.getFullList @transport.one(@conf.url, id).all('dispatch_unit_types')

    IncidentPrioritiesManagerBase.addDispatchUnitType = (id, data) ->
      @transport.one(@conf.url, id).all('dispatch_unit_types')
      .post(_.pick(data, @getObject('incidentPriorityDispatchUnitType')))

    IncidentPrioritiesManagerBase.removeDispatchUnitType = (incidentPriorityId, dispatchUnitTypeId) ->
      @transport.one(@conf.url, incidentPriorityId).one('dispatch_unit_types', dispatchUnitTypeId).remove()

    IncidentPrioritiesManagerBase.getDispatchUnitTypeForm = ->
      [
        key: 'dispatchUnitType'
        name: 'Dispatch unit type'
        type: 'select'
        templateOptions:
          label: 'Dispatch unit type'
          placeholder: 'Dispatch unit type'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: DispatchUnitTypesManager.getFullList().$object
      ,
        key: 'minimalAmount'
        name: 'minimalAmount'
        type: 'input'
        templateOptions:
          label: 'Minimal Amount'
          placeholder: 'Minimal amount'
          required: true
      ]

    assignDispatchUnitType =
      [
        icon: 'extension'
        text: 'datatable.tooltips.addDispatchUnitType'
        action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/incidents/views/incident-priority-dispatch-unit-types.modal.tpl.html'
              controller: 'IncidentPriorityDispatchUnitTypesCtrl'
              controllerAs: 'ctrl'
              locals:
                id: id
      ]



    IncidentPrioritiesManagerBase.addExtraAction(assignDispatchUnitType)

    IncidentPrioritiesManagerBase.addDetailEditAction('editCode',
      [
        title: 'Edit Code'
        entityManager: IncidentPriorityCodesManager
      ]
    )

    IncidentPrioritiesManagerBase.addDetailEditAction('editDispatchUnitType',
      [
        title: 'Edit Dispatch unit type'
        entityManager: IncidentPriorityDispatchUnitTypesManager
      ]
    )

    IncidentPrioritiesManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:IncidentPriority entities'

    IncidentPrioritiesManagerBase
